<template>
  <!--  TODO FILTER  -->
  <VForm
    :value="filterFormData"
    @input="SET_FILTER_FORMDATA"
    class="form-filter"
    :class="{ 'mb-20': !isMobile }"
    ref="formDataOrderRef"
    @request="filterSubmit"
  >
    <div class="grid-filter mb-30" :class="getClass">
      <VInputDate
        label="По дате поступления заказа"
        ref="dateFilterRef"
        :value="dateStart"
        :value-end="dateFinish"
        @value-changed="setFilterDateOrderCreate"
      />

      <VInput label="По названию компании:" name="client_name" />

      <VSelect
        label="Сортировка по:"
        name="sort"
        :options="filterSortList"
      />

      <VSelect
        label="По ответственному:"
        name="responsible"
        :options="responsibleList"
      />

      <VSelect
        label="По типу заказа:"
        name="order_type"
        multiple
        :options="propertiesAll.order_type"
      />

      <transition name="fade">
        <VSelect
          v-if="!isOrderList"
          label="По этапу:"
          name="stage"
          multiple
          :options="propertiesAll.status"
        />
      </transition>

      <VSelect
        label="Цех производства:"
        name="workshop"
        multiple
        :options="workshopOptions"
        @change="setWorkshopSelected"
      />

      <VSelect
        label="По способу навивки:"
        name="winding_method"
        multiple
        :options="windingMethodOptionsFiltered"
      />

      <VSelect
        label="По типу пружин:"
        name="spring_type"
        multiple
        :options="propertiesAll.product_type"
      />

      <VSelect
        label="По диаметру проволоки:"
        name="d_wires"
        searchable
        multiple
        :options="dWireAll"
      />

      <VSelect
        label="По самовывозу:"
        name="is_pickup"
        placeholder="Все заказы"
        :options="pickupOptions"
      />

      <VSelect
        label="По готовности документов:"
        name="documents_are_ready"
        placeholder="Все заказы"
        :options="documentOptions"
      />

      <VSelect
        label="По рекламации:"
        name="reclamation"
        :options="reclamationOptions"
        multiple
      />
      <VCheckbox
        v-model="isDeleted"
        name="is_deleted"
        v-if="isAdmin && isOrderList"
        label="Показать удаленные"
        type="switch"
      />
    </div>

    <div class="buttons button-group">
      <VButton text="Применить" type="submit" :class="{ 'btn--100': isMobile }" color="primary" />

      <VButton text="Сбросить" :class="{ 'btn--100': isMobile }" @click.native="onClickResetBtn" />
    </div>
  </VForm>
</template>

<script>

import VInputDate from '@/components/Form/VInputDate.vue'
import VButton from '@/components/simple/button/VButton.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import VSelect from '@/components/Form/VSelect.vue'
import VForm from '@/components/Form/VForm.vue'
import VCheckbox from '@/components/Form/VCheckbox.vue'
import { mixinWorkshopAndWindingMethodOptions } from '@/mixins/views/mixinWorkshopAndWindingMethodOptions'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { prepareFormDataTrueFalseOptions } from '@/utils/form/prepareFormDataTrueFalseOptions'
import { transformDateForSend } from '@/utils/form/date'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
import getValuesArray from '@/utils/array/getValuesArray'
import { mapTrueFalseToOptions } from '@/utils/map/mapTrueFalseToOptions'
import { toItemWithIdByIdx } from '@/shared/lib/utils/map/handbook'

const pickupOptions = [
  'отобразить только самовывоз',
  'скрыть самовывоз'
].map(mapTrueFalseToOptions)

const documentOptions = [
  'подготовлены',
  'не подготовлены'
].map(mapTrueFalseToOptions)

const reclamationOptions = [
  {
    name: 'Внутренняя',
    key: 'is_inner_reclamation',
    value: true
  },
  {
    name: 'Внешняя',
    key: 'is_outer_reclamation',
    value: true
  }
].map(toItemWithIdByIdx)

export default {
  components: {
    VCheckbox,
    VButton,
    VInput,
    VForm,
    VSelect,
    VInputDate
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixinWorkshopAndWindingMethodOptions],
  data: () => ({
    isDeleted: false,
    filterSortList: [
      { id: 2, name: 'по дате отгрузки', slug: 'date_finished' },
      { id: 3, name: 'по срокам', slug: 'timings.days' }
    ]
  }),
  created () {
    if (this.filterFormData.workshop) {
      this.setWorkshopSelected(this.filterFormData.workshop)
    }
  },

  computed: {
    ...mapState('registerPage', {
      filterFormData: 'filterFormData',
      dateOrderCreate: 'dateOrderCreate',
      search: 'search',
      queryParams: 'queryParams'
    }),
    ...mapState('users', {
      responsibleList: 'all'
    }),
    ...mapState('order', {
      filterByDateStatus: 'filterByDateStatus',
      isLoadingOrderList: 'isLoadingOrderList'
    }),
    ...mapState('consignment', ['isLoadingConsignmentList']),
    ...mapGetters({
      propertiesAll: 'records/propertiesAll',
      dWireAll: 'records/dWireAll',
      isAdmin: 'permission/isAdmin'
    }),
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),

    pickupOptions: () => pickupOptions,
    documentOptions: () => documentOptions,
    reclamationOptions: () => reclamationOptions,

    modelData: {
      get () {
        return this.dateOrderCreate
      },
      set (data) {
        this.SET_DATE_ORDER_CREATE(data.value)
      }
    },

    isOrderList () {
      return this.$route.name.indexOf('Order') !== -1
    },

    getClass () {
      return this.inModal ? 'grid-filter--col-1 pr-12 custom-scroll' : ''
    },

    dateStart () {
      return this.dateOrderCreate?.date_start ?? null
    },

    dateFinish () {
      return this.dateOrderCreate?.date_finish ?? null
    }
  },

  methods: {
    ...mapMutations('registerPage', {
      SET_FILTER_FORMDATA: 'SET_FILTER_FORMDATA',
      RESET_SEARCH: 'RESET_SEARCH',
      RESET_FILTER: 'RESET_FILTER',
      SET_DATE_ORDER_CREATE: 'SET_DATE_ORDER_CREATE',
      SET_QUERY_PARAMS: 'SET_QUERY_PARAMS'
    }),
    ...mapActions({
      fetchOrderListData: 'order/fetchOrderListData',
      fetchConsignmentListData: 'consignment/fetchConsignmentListData'
    }),
    ...mapMutations('order', {
      TOGGLE_FILTER_OVERDUE: 'TOGGLE_FILTER_OVERDUE',
      TOGGLE_FILTER_DEADLINE_IS_COMING: 'TOGGLE_FILTER_DEADLINE_IS_COMING',
      RESET_FILTER_BY_DATE_STATUS: 'RESET_FILTER_BY_DATE_STATUS'
    }),

    async filterSubmit (data) {
      const prepareFormData = prepareFormDataTrueFalseOptions(data, [
        'is_pickup',
        'documents_are_ready'
      ])
      let formData = {
        ...this.dateOrderCreate,
        ...prepareFormData,
        sort: prepareFormData.sort?.slug || '-id',
        user_id: prepareFormData.responsible?.id || null,
        responsible_id: prepareFormData.responsible?.id || null,
        is_inner_reclamation: this.is_inner_reclamation || null
      }
      delete formData.responsible

      const keyListForIdArray = [
        'workshop',
        'order_type',
        'winding_method',
        'spring_type'
      ]

      formData = prepareFormDataIdArray(formData, keyListForIdArray)
      formData.d_wires = formData.d_wires
        ? getValuesArray(formData.d_wires, 'name')
        : null

      if (formData.reclamation) {
        for (const option of formData.reclamation) {
          formData[option.key] = option.value
        }
        delete formData.reclamation
      }

      if (formData.is_deleted === false) {
        delete formData.is_deleted
      }

      this.$store.commit('consignment/SET_FILTER', formData)
      this.$store.commit('order/SET_FILTER', formData)

      this.$emit('closeModal')
      this.$emit('switchLoadList')
    },

    setFilterDateOrderCreate (data) {
      this.SET_DATE_ORDER_CREATE(transformDateForSend(data.value))
    },

    async onClickResetBtn () {
      this.RESET_SEARCH()
      this.resetFilter()
      this.$emit('switchLoadList')
      this.$emit('closeModal')
    },

    resetFilter () {
      this.resetRefs()
      this.RESET_FILTER_BY_DATE_STATUS()
      this.RESET_FILTER()
      this.$store.commit('order/SET_FILTER', {})
      this.$store.commit('consignment/SET_FILTER', {})
      this.SET_QUERY_PARAMS({})
    },

    resetRefs () {
      // this.$refs.formDataOrderRef.clear()
      this.$refs.dateFilterRef.$refs.dateRef._flatpickr.clear()
    }
  }
}
</script>
