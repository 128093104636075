<template>
  <div>
    <div class="d-flex ai-c jc-sb mb-40">
      <div class="order-list__header">
        <div class="d-flex gap-xxs ai-c">
          <VButton
            size="menu"
            icon="filter"
            color="primary-sm"
            :active="isFilter"
            @click.native="openFilter"
          />

          <VInput
            v-model="searchValue"
            type="search"
            rolled
            placeholder="Поиск по номеру заказа и артикулу"
            :width=" !isMobile ? '460px' : '100%'"
          />

          <ButtonRefresh
            :disabled="isLoadingList"
            @click.native="switchLoadList"
          />
        </div>

        <div class="d-flex gap-s f-wrap">
          <VCheckbox
            v-if="overdueCount"
            v-model="filterByDateStatus.overdue"
            class="filter__checkbox ml-20"
            label="Просроченные заказы"
            counter-color="red"
            :counter="overdueCount"
            :isDisabled="isLoadingList"
            @click.native="onClickCountRedFlag"
          />

          <VCheckbox
            v-if="deadlineIsComingCount"
            v-model="filterByDateStatus.deadline_is_coming"
            class="filter__checkbox"
            label="Срок подходит"
            counter-color="orange"
            :counter="deadlineIsComingCount"
            :isDisabled="isLoadingList"
            @click.native="onClickCountYellowFlag"
          />
        </div>

      </div>

      <router-link v-if="permissionCreateOrder" :to="{ name: 'OrderCreate' }">
        <VButton
          class="as-c"
          text="Создать заказ"
          icon="create"
          size="sm"
          color="primary-sm"
        />
      </router-link>
    </div>

    <transition name="fade">
      <OrderFilter v-if="isFilter" @switchLoadList="switchLoadList" />
    </transition>
    <BaseModal
      v-if="isModal === 'filter'"
      class="modal--sm modal--filter"
      :hidden-close-btn="true"
      @close="closeModal"
    >
      <OrderFilter in-modal @closeModal="closeModal"  @switchLoadList="switchLoadList" />
    </BaseModal>

    <!--    TODO убираем табы после адаптива-->
    <NavTabsSwiper v-if="false" :tabs-list="tabs" class-name-nav-item="" />

    <router-view />
  </div>
</template>

<script>
import ButtonRefresh from '@/components/simple/button/ButtonRefresh'
import VButton from '@/components/simple/button/VButton'
import VInput from '@/components/Form/Vinput/VInput'
import NavTabsSwiper from '@/shared/ui/tabs/NavTabsSwiper.vue'
import VCheckbox from '@/components/Form/VCheckbox.vue'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { setCurrentRouteQueryPage } from '@/utils/router'
import OrderFilter from '@/views/Production/Order/OrderFilter.vue'
import { mixinIsFilter } from '@/mixins/mixinIsFilter'
import { mixinModal } from '@/mixins/modal/mixinModal'
import BaseModal from '@/components/ui/Modal/BaseModal.vue'

export default {
  components: {
    BaseModal,
    OrderFilter,
    VCheckbox,
    NavTabsSwiper,
    ButtonRefresh,
    VButton,
    VInput
  },
  mixins: [mixinModal, mixinIsFilter],

  computed: {
    ...mapState('registerPage', {
      search: 'search'
    }),
    ...mapState('tabsOrderList', ['tabs']),
    ...mapState('order', {
      filterByDateStatus: 'filterByDateStatus',
      isLoadingOrderList: 'isLoadingOrderList'
    }),
    ...mapGetters('order', {
      overdueCount: 'overdueCount',
      deadlineIsComingCount: 'deadlineIsComingCount'
    }),
    ...mapState('consignment', ['isLoadingConsignmentList']),
    ...mapGetters({
      permissionCreateOrder: 'permission/permissionCreateOrder'
    }),
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),

    searchValue: {
      get () {
        return this.search
      },
      set (value) {
        this.SET_SEARCH(value)
        this.switchLoadList()
      }
    },

    isOrderList () {
      return this.$route.name.indexOf('Order') !== -1
    },

    isLoadingList () {
      return this.isLoadingOrderList || this.isLoadingConsignmentList
    }
  },

  created () {
    this.SET_QUERY_PARAMS()
  },

  methods: {
    ...mapMutations('registerPage', {
      SET_SEARCH: 'SET_SEARCH',
      SET_QUERY_PARAMS: 'SET_QUERY_PARAMS'
    }),
    ...mapActions({
      fetchOrderListData: 'order/fetchOrderListData',
      fetchConsignmentListData: 'consignment/fetchConsignmentListData'
    }),
    ...mapMutations('order', {
      TOGGLE_FILTER_OVERDUE: 'TOGGLE_FILTER_OVERDUE',
      TOGGLE_FILTER_DEADLINE_IS_COMING: 'TOGGLE_FILTER_DEADLINE_IS_COMING'
    }),

    async onClickCountRedFlag () {
      this.TOGGLE_FILTER_OVERDUE()
      await this.switchLoadList()
    },

    async onClickCountYellowFlag () {
      this.TOGGLE_FILTER_DEADLINE_IS_COMING()
      await this.switchLoadList()
    },

    async switchLoadList () {
      if (+this.$route.query.page !== 1) {
        return setCurrentRouteQueryPage(1)
      }

      if (this.isOrderList) {
        await this.fetchOrderListData()
        return
      }
      await this.fetchConsignmentListData()
    },

    openFilter () {
      if (this.isMobile) {
        this.openModal('filter')
      } else {
        this.toggleFilter()
      }
    }
  }
}
</script>
